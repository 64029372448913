<template>
  <div class=''>
    <img src="@/assets/images/header/jdPage.png" class="w-max">
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  created () {
    this.id = this.$route.params.id
  },

  mounted () { },

  methods: {}
}

</script>
<style lang='scss' scoped>
</style>